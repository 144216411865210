/* Global imports */
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect, { components } from "react-select";
/* Local imports */
import variables from "../styles/_settings.scss";


/* Component definition */
const Select = ({
  className,
  options,
  placeholder,
  value,
  onValueChange,
  ...rest
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? variables.black
        : state.isFocused
        ? "white"
        : "black",
      backgroundColor: state.isSelected
        ? "#e5e5e5"
        : state.isFocused
        ? variables.green
        : variables.white,
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "2px solid #E7E8ED",
      borderRadius: "0",
      "&:hover": {
        borderBottom: "2px solid #E7E8ED",
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: variables.green,
      "&:hover": {
        color: variables.green,
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: variables.green,
      "&:hover": {
        color: variables.green,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      margin: 0,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: variables.white,
      border: `1px solid ${variables.blue}`,
      borderRadius: "6px",
      height: "20px",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      padding: "0 4px",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      marginBottom: "8px",
      verticalAlign: "top",
    }),
  };
  return (
    <ReactSelect
      className={className}
      styles={customStyles}
      options={options}
      placeholder={placeholder}
      isClearable={false}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      value={value}
      onChange={onValueChange}
      {...rest}
    />
  );
};
/* PropTypes */
Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  onValueChange: PropTypes.func,
};
Select.defaultProps = {
  options: [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ],
  placeholder: "Select an option",
};

/* Local utility functions */
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon
        icon={faAngleDown}
        style={{ height: "20px", width: "20px" }}
      />
    </components.DropdownIndicator>
  );
};

/* Styles */

export default Select;
